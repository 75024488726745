body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Work Sans';
  src: local('WorkSans'),
    url(./assets/fonts/work-sans/WorkSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  font-weight: 500;
  src: local('WorkSans'),
    url(./assets/fonts/work-sans/WorkSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  font-weight: 600;
  src: local('WorkSans'),
    url(./assets/fonts/work-sans/WorkSans-SemiBold.ttf) format('truetype');
}

/*
  Define here the CSS styles applied only to Safari browsers
  (any version and any device)
  https://gist.github.com/jbutko/6718701
*/
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /*
      Material-UI Grid spacing issues on Safari
      https://github.com/mui-org/material-ui/issues/17142#issuecomment-679163447
    */
    .MuiGrid-spacing-xs-4 {
      margin-left: 0;
      margin-right: 0;
      transform: translateX(-16px);
    }

    .MuiGrid-spacing-xs-3 {
      margin-left: 0;
      margin-right: 0;
      transform: translateX(-12px);
    }

    .MuiGrid-spacing-xs-2 {
      margin-left: 0;
      margin-right: 0;
      transform: translateX(-8px);
    }

    .MuiGrid-spacing-xs-1 {
      margin-left: 0;
      margin-right: 0;
      transform: translateX(-4px);
    }
  }
}
